/*

    DATABASE: hp037900

*/

import { faCheck, faHourglass, faShoppingCart, faXmark } from "@fortawesome/free-solid-svg-icons";


global.testing = process.env.REACT_APP_TEST == 0 ? false : true;
global.testing_menu = false;
global.appstore_publishing = false;

// DECODE KEY
global.code_key = '%E<gw=$0]1+W|4!Bt#;@:>.^}Q{K(*elq/G,po39zR&[)-Tr6iy_u8ADM7SJH5NPvIObhZCUFn2YVXjLksmxfdac';

global.max_screen = 1400;
global.max_width = 950;
global.app_name = 'Les Nouvelles Esthétiques';

// KEYBOARD PADDING -> ak je zobrazená klávesnica -> padding-bottom
global.keyboard_padding = 500;
global.keyboard_padding_small = 300;
global.empty_padding = 100;
global.list_padding = 150;

// DATABAZA
global.web = global.testing == true ? 'https://lne.datadream-test.sk' : 'https://lne.datadream.sk';
global.db_url = global.web + '/app_route/';


// WINDOW RADIUS / OFFSET
global.radius = 8;
global.row_radius = 4;
global.offset = 8;

// NAZVY LOKALNE ULOZENYCH SUBOROV
global.filename_settings = '';
global.filename_token = 'apptoken.tmp';

global.items_max = 20;
global.elevation_over = 4;
global.elevation_form = 2;
global.elevation = 1;
global.max_items_on_page = [
    { id: 10, label: '10' },
    { id: 20, label: '20' },
    { id: 35, label: '35' },
    { id: 999999, label: 'všetky' }
];
global.goto_start_delay = 200;

global.date_start = '2023-01-01';
global.date_endless = '3000-01-01';

// PADDING -> Small screnn
global.smallOffset = 8;

// Cas potrebný na zatvorenie dialogového okna
global.dialog_close_delay = 150

// LISTING - jednotlivé položky - RADIUS
global.items_radius = 8;

// **********************************************************************
// VELKOST DIALOGOVEHO OKNA
// **********************************************************************
global.dialog_max_width = 1200;
global.dialog_max_height = 950;

// **********************************************************************
// TYPY UČTOV
// **********************************************************************
global.account_disabled = 0;

// ******************************************************************************************
// MENU
// ******************************************************************************************
global.menu_width = 260;
global.menu_info_height = 75;
global.statusbar_height = 40;
global.topmenu_height = 42;
global.status_height = 0;
global.menutitle_height = 150;
global.menu_elevation = 5;
global.theme_menu_selected = '#015FBD';
global.widow_bar_height = 40; // WINDOW TOP BAR
global.dialog_bar_height = 50;
global.dashboard_bar_height = 40;

// SUB MENU
global.sub_menu_width = 200; // Sub menu v oknách na lavej strane

// ******************************************************************************************
// PISMO
// ******************************************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 14;
global.font_xsmall = 15;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_table = 14;


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 750; //650
global.device_medium = 1200;
global.screen_max = 950;
global.tablet_width = 1200;

// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    name: 'Datadream s.r.o.',
    street: 'Viničná 52/16',
    psc: '951 93',
    town: 'Machulince',
    state: 'Slovensko',
    mobil1: '0905 - 668 294',
    mobil2: '0944 - 411 144',
    call: '0905 - 668 294',
    email: 'info@datadream.sk',
    ico: '54084253',
    dic: '2121566524',
    district: 'Zlaté Moravce',
};

global.app_name1 = 'LNE';
global.app_name2 = 'Manager';


// **********************************************************************
// ORDERING ITEMS - zoradovanie SORT_DOWN, SORT_UP
// **********************************************************************
global.order = {
    up: 'desc',
    down: 'asc'
}

// **********************************************************************
// EXPORT TYPES - typy exportov
// **********************************************************************
global.export_type = {
    pdf: 0,
    xls: 1,
    csv: 2
}

global.export_type_name = [
    'pdf',
    'xls',
    'csv'
]

// **********************************************************************
// IMAGE TYPES
// **********************************************************************
global.image = {
    avatars: 0,
    images: 1,
    gallery: 2
}

// **********************************************************************
// IMAGE TYPES
// **********************************************************************
global.gallery = {
    obklady: 0,
    dlazby: 1,
    muriva: 2,
    ostatne: 3
}


// **********************************************************************
// SYSTEM / USER SETTINGS - DEFAULT VALUES
// **********************************************************************
global.settings_default = {
    id: 0,
    dph: 20,
    roles: false,
    foles_tree: false,
    precision: 0,
    sms_notify: false,
    sms_sender: '',
    sms_unicode: false,
    sms_credit: 0.00,
    product_auto_code: false,
    product_codes: [
        { id: 0, enabled: false, label: '' },
        { id: 1, enabled: false, label: '' },
        { id: 2, enabled: false, label: '' },
        { id: 3, enabled: false, label: 'Vendor' },
        { id: 4, enabled: false, label: 'Ean' },
    ],
    stocks: false,
    stock_default: 0,
    customers_codes: [
        { id: 0, enabled: false, label: '' },
        { id: 1, enabled: false, label: '' },
        { id: 2, enabled: false, label: '' },
    ],
};

global.settings_user_default = {
    id: 0,
    name_format: 0,
};

global.settings_numbers = {
    default_pattern: JSON.stringify({ char1: 0, char2: 1, char3: 0, decimal: 6, pattern: '@year-@number6', prefix: '', value1: 1, value2: 3, value3: 0, example: "2023-000001" }),
};

// STAV OBJEDNAVKY KREDITOV - SMS STATUS
global.sms_credit_status = [faShoppingCart, faHourglass, faCheck, faXmark];

// TRANSAKCIE - upravy skladových zásob
global.transactions = {
    initial: 0,         // prvotný stav skladu pri zakladaní karty produktu
    adjustment: 1,      // úprava skladu
    transport: 2        // presun skladu
}

// CISELNIKY - ID
global.codebook = {
    reasons: 1,
    reasons_transport: 2
}


// FARBA ZNACIEK PRODUKTOV
global.flag_colors = [
    '#000000',
    '#DD0000',
    '#00DD00',
    '#0000DD',
    '#DDDD00',
    '#0088DD',
    '#DD00DD'
];

// ŠTATY
global.states = [
    { id: 0, name: 'Slovensko', money: [{ id: 0, name: 'Sk' }, { id: 2, name: '€' }] },
    { id: 1, name: 'Česko', money: [{ id: 1, name: 'Kč' }] },
    { id: 2, name: 'Československo', money: [{ id: 1, name: 'Kčs' }] },
];

// PENAZNA MENA
global.money = [
    { id: 0, name: 'Sk' }, { id: 1, name: 'Kč' }, { id: 2, name: '€' }
];
