/*

    DATABASE: xb036300

*/

import img_background from './react/app/background_dash.jpg';
import img_menu1 from './react/app/background_menu_3.jpg';
import intro_background from './react/app/background_intro.jpg';


global.themes = [
    {
        id: 0,
        intro_image: intro_background,
        background_image: intro_background,
        background_dashboard: img_background,
        menu_image: img_menu1,
        title_card: '#BBD6FD', // Hlavná obrazovka title user card
        desktop_background: '#D0D0D0', // POZADIE APLIKACIE
        top_bar: 'linear-gradient(to right,#FFFFFF,#FFFFFF)', // farba TOP MENU #CADEFC
        menu_info: '#5E81B5', // Info o uzivatelovi
        menu_icon: '#283E7E', // pozadie ikony na menu
        menu_select: '#C5DBFB',
        menu_select_border: '#77BBFF',
        dialog_background: 'linear-gradient(220deg, #FFFFFF 0%, #92BBEC 66%)',
        window_background: 'linear-gradient(220deg, #FFFFFFFF 0%, #92BBECFF 66%)',
        window_bar_color: 'linear-gradient(to right,#BCD7FD,#BCD7FD)',
        status_bar: '#A0A7B0',
        icon_background: 'linear-gradient(135deg,#5E94B4,#1C5B80)',
        shortcut_background: 'linear-gradient(220deg,#0C346B,#205CCA)',
        line: 'linear-gradient(220deg, #5F7AA100 0%, #5F7AA1FF 50%, #5F7AA100 100%)',
        logo_background: '#D0DDEF',
        rainbow: 'linear-gradient(220deg, #FF2222 50%, #0077FF 50%)',
        back: '#F0F0F0',
        error: '#CA0000',
        ok: '#15A115',
        menu_background: '#205888',

        // SUB-MENU
        sub_menu_back: '#D8D8D8',
        sub_menu_border: '#0066FF',

        // THEMED COLORS
        lighteen: '#CADEFC',
        lighter: '#BBD6FD',
        light: '#425ABD',
        medium: '#1F3679',
        dark: '#19275A',
        darker: '#0F0F2B',

        lighteen_blue: '#E0EFFF',
        lighter_blue: '#92BBEC',
        light_blue: '#2A8EFF',
        blue: '#205CCA',
        dark_blue: '#003BA4',
        darker_blue: '#041A47',

        lighteen_red: '#FFE4E2',
        lighter_red: '#F99891',
        light_red: '#F77268',
        red: '#F44336',
        dark_red: '#C3170B',
        darker_red: '#801000',

        lighteen_green: '#E2FFE3',
        light_green: '#A6FA9D',
        green: '#46F436',
        dark_green: '#18BB18',
        darker_green: '#15A115',
        darkest_green: '#107010',

        yellow: '#FFDD7A',
        badge: '#A12E28',
        placeholder: '#cccccc',

        grayer: '#f7f7f7',
        gray: '#eeeeee',
        light_gray: '#dedede',
        xxgray: '#CCCCCC',
        middle_gray: '#A0A0A0',
        medium_gray: '#888888',
        dark_gray: '#666666',
        darker_gray: '#252525',

        lighteen_violet: '#E9E2FF',
        light_violet: '#FFAAFF',
        violet: '#BB00BB',
        orange: 'orange',
        dark_orange: '#D47300',
        light_cyan: '#80A0FF',
        cyan: 'cyan',
        dark_cyan: '#1080B0',
        brown: 'brown',

        white: '#FFFFFF',
        black: '#000000',

        // BUTTONS
        button_light: '#92C2E7',
        button_medium: '#226AA3',
        button_dark: '#103450',

        button_ok: '#103450',
        button_cancel: '#226AA3',
        button_add: '#0080E6',
        button_gray: '#DDDDDD',
        button_action: '#92C2E7',
        button_delete: '#AA0000',

        // Conditions - designer
        conditions: '#005FA9',

        // NEAKTIVNA POLOZKA - farba pozadia
        disabled: '#D0D0D0',
        disabled_border: '4px solid #FF0000',

        // VARIATION COLOR - product variant
        variation: '#F2FAFF',
        border_variation: '4px solid #A0E0FF',

        // GROUPED COLOR - produkt balíček
        grouped: '#FFF7FF',
        border_grouped: '4px solid #FFA0FF',


        // Super Admin
        super_admin: '#FF7700',

        // default border
        border: '1px solid #dedede',

        // hlavný sklad
        stock: '4px solid #A0E0FF',

        // indo dialog
        info: '#BBDDFF',

        // dashboard
        dashboard: '#FFFFFFA0',
        window_bar_color_light: 'linear-gradient(to right,#D6E6FE,#D6E6FE)',

        dashboard_blue: '#B4E0FF',
        dashboard_red: '#FFB5B4',
        dashboard_green: '#B4FFC4',
        dashboard_orange: '#FFDBB4',
        dashboard_violet: '#FFD0FF',
        dashboard_blue_light: '#AABBFF',
    }
]

