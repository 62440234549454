/*
*
* =================================================================================================================
* GALERIA - obklady
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Avatar, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Line, Loading, SelectInput, ShowError, ShowOK, FormLabel, ContactCompany, DialogTabs, TextLine, ButtonNew, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faBookOpen, faDollar, faImages, faInfoCircle, faLayerGroup, faList, faLocationDot, faPlusCircle, faPrint, faQuestion, faTable, faTableCells, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, GetLeftPosition, GetPages, GetPrice, IsEmpty } from './functions';
import { Photos } from './photos';
import { compareDates, GetDatum2, getDaysBetweenDates, Today } from './functions_date';
import { Customer, CustomerPayment, CustomerSubscription } from './customers';


export const SubscriptionList = (props) => {

    var lang = props.lang;
    let color = global.themes[props.theme];

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        status: 0
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // LISTING - GRID / LINES
    const [listingTyp, setListingTyp] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [itemsMax, setItemsMax] = useState(global.items_max);

    // FILTROVANIE
    const [search, setSearch] = useState('');
    const [menuID, setMenuID] = useState(0);

    // DIALOGS
    const [showPayments, setShowPayments] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const [customer, setCustomer] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const boxSize = 280;
    const rowSize = 100;
    const paginationHeight = 50;
    const rowHeight = 94;

    let menu = [
        { id: 1, name: lang.subscription_status[0] },
        { id: 2, name: lang.subscription_status[1] },
        { id: 3, name: lang.subscription_status[2] },
    ]

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, itemsMax);

            running = true;
        }

    }, []);

    const db_get = async (page, filter, items_max) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'list_subscriptions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'magazine_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_get(0, filter, itemsMax);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeItemsMax = (value) => {
        setItemsMax(value);
        db_get(0, false, value);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, itemsMax);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_subscription_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectCustomer = (item) => {
        setCustomer(item.customer);
        if (item.customer != false) {
            setShowCustomer(true);
        }
    }

    const CustomerResult = (redraw_) => {
        setShowCustomer(false);

        if (redraw_ == true) {
            db_get(page, filter, itemsMax);
        }
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowPayments(true);
    }

    const PaymentResult = (redraw_) => {
        setShowPayments(false);

        if (redraw_ == true) {
            db_get(0, filter, itemsMax);
        }

    }

    const ChangeMenu = (id) => {
        setMenuID(id);

        var filter_ = filter;

        if (id == 0) {
            delete (filter_.status);
        } else {
            filter_.status = id;
        }

        db_get(0, filter_, itemsMax);
    }


    const Search = (txt) => {
        setSearch(txt);

        var filter_ = filter;

        if (txt.trim() == '') {
            delete (filter_.search);
        } else {
            filter_.search = txt;
        }

        db_get(0, filter_, itemsMax);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faList} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.Block, borderBottom: '1px solid ' + color.light_gray, height: 90 }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        {/*
                                        <TextInput search redraw theme={props.theme} width={250} enabled={true} lang={lang} value={search} label={''} placeholder={lang.payment_search_code} func={(txt) => Search(txt)} />
                                        */}
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        <SelectInput all={lang.all_records} theme={props.theme} enabled={true} lang={lang} value={menuID} items={menu} field={'name'} label={lang.mobil} radius={global.radius} width={250} func={(item) => ChangeMenu(item)} />
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextXTiny, marginTop: 2, color: color.dark_gray }}>{lang.subscription_list}</p>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - 90 - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div id={'id_subscription_start'} style={{ ...styles.Block }}></div>

                                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap', marginTop: 20 }}>
                                    {items != false ? items.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            onClick={() => SelectItem(item)}
                                            style={{
                                                ...styles.Block,
                                                borderRadius: global.items_radius,
                                                backgroundColor: item.expired == true ? color.lighteen_red : color.white,
                                                cursor: 'pointer',
                                                margin: 5,
                                                width: '99%',
                                                height: rowHeight,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={item.avatar} size={rowHeight - 16} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '45%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div onClick={(e) => { e.stopPropagation(); SelectCustomer(item) }} style={{ ...styles.BlockLeft, width: 26 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: color.medium_gray }} icon={faUser} />
                                                            </div>
                                                            <TextLine text={item.company != '' ? item.company + ', ' + item.name + ' ' + item.surname : item.name + ' ' + item.surname} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 8 }}>
                                                        <TextLine text={item.magazine} fontSize={global.font_tiny} color={color.black} />
                                                        <TextLine text={item.subscription} fontSize={global.font_tiny} color={color.black} />
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny }}>{lang.subscription_date_end}</p>
                                                    <p style={{ ...styles.TextXSmall }}> {GetDatum2(item.date_end)}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.invoice_created == true ?
                                                        <Chip size='small' label={lang.payment_generated_} style={{ backgroundColor: color.light_gray, color: color.black }} />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 140, marginLeft: 20, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {compareDates(Today(), item.date_end) == 1 ?
                                                        <>
                                                            <Chip size='small' label={lang.subscription_expired} style={{ backgroundColor: color.dark_red, color: color.white }} />
                                                            <p style={{ ...styles.TextXTiny, marginTop: 2 }}>dní: {getDaysBetweenDates(item.created, item.date_end)}</p>
                                                        </>
                                                        :
                                                        item.ending == true ?
                                                            <Chip size='small' label={lang.subscription_near_expired} style={{ backgroundColor: color.blue, color: color.white }} />
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }
                                </div>

                                {/* PAGINATION */}
                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    {/*
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={itemsMax} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeItemsMax(item)} />
                                        </div>
                                    </div>
                                </div>
                                */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showCustomer == true ?
                    <Customer item={customer} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomerResult.bind()} />
                    : null
            }

            {
                showPayments == true ?
                    <CustomerSubscription customerID={itemSelected.customer_id} item={itemSelected} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={PaymentResult.bind()} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null
            }

        </Paper >
    );
}

