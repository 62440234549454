/*
*
* =================================================================================================================
* GALERIA - obklady
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Avatar, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Line, Loading, SelectInput, ShowError, ShowOK, FormLabel, ContactCompany, DialogTabs, ButtonNew, TextLine, FormSelect, FormNote, DeleteButton, EmailPreview, TextInput, DialogSMS, DialogSMSLogs } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, TextField, Tooltip, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faBookOpen, faCopy, faDollar, faFileText, faImages, faInfoCircle, faLayerGroup, faLocationDot, faMagnifyingGlass, faMinus, faPaperPlane, faPlusCircle, faPrint, faQuestion, faTable, faTableCells, faTrash, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, CoeficientToPercentage, Debug, FormatMoney, GetLeftPosition, GetPages, GetPrice, IsEmpty } from './functions';
import { Photos } from './photos';
import { AddDays, addMonthsToDate, GetDate3, GetDatum2, GetMiliseconds, Today } from './functions_date';


export const Customers = (props) => {

    var category_id = props.id;

    var lang = props.lang;
    let color = global.themes[props.theme];

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        enabled: true,
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // LISTING - GRID / LINES
    const [listingTyp, setListingTyp] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [itemsMax, setItemsMax] = useState(global.items_max);
    const [search, setSearch] = useState('');

    // DIALOGS
    const [showCustomer, setShowCustomer] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const boxSize = 280;
    const rowSize = 100;
    const paginationHeight = 50;
    const rowHeight = 94;


    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, itemsMax);

            running = true;
        }

    }, []);

    const db_get = async (page, filter, items_max) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'customers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'gallery_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_get(0, filter, itemsMax);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeItemsMax = (value) => {
        setItemsMax(value);
        db_get(0, false, value);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, itemsMax);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_customers_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowCustomer(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowCustomer(true);
    }

    const CustomerResult = (redraw_) => {
        setShowCustomer(false);

        if (redraw_ == true) {
            db_get(0, filter, itemsMax);
        }

    }

    const Search = (txt) => {
        setSearch(txt);

        var filter_ = filter;

        if (txt.trim() == '') {
            delete (filter_.search);
        } else {
            filter_.search = txt;
        }

        db_get(0, filter_, itemsMax);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faUsers} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>

                            <div style={{ ...styles.Block, borderBottom: '1px solid ' + color.light_gray, height: 90 }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        <TextInput search redraw theme={props.theme} width={250} enabled={true} lang={lang} value={search} label={''} placeholder={lang.customer_search} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.customer_new}
                                        </Button>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextXTiny, marginTop: 2, color: color.dark_gray }}>{lang.customer_list}</p>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - 90 - 6, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div id={'id_customers_start'} style={{ ...styles.Block }}></div>

                                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap', marginTop: 20 }}>
                                    {items != false ? items.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            onClick={() => SelectItem(item)}
                                            style={{
                                                ...styles.Block,
                                                borderRadius: global.items_radius,
                                                backgroundColor: item.active == false ? color.disabled : color.white,
                                                cursor: 'pointer',
                                                margin: 5,
                                                width: '99%',
                                                height: rowHeight,
                                                borderLeft: item.active == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '30%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <ContactCompany company={item.company} name={item.name} surname={item.surname} theme={props.theme} />
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '25%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <p style={{ ...styles.TextTiny }}>{item.street} {item.street_number}</p>
                                                        <p style={{ ...styles.TextTiny }}>{item.psc} {item.town}</p>
                                                        <p style={{ ...styles.TextTiny }}>{item.country}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '25%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <p style={{ ...styles.TextTiny }}>{item.delivery_street} {item.delivery_street_number}</p>
                                                        <p style={{ ...styles.TextTiny }}>{item.delivery_psc} {item.delivery_town}</p>
                                                        <p style={{ ...styles.TextTiny }}>{item.delivery_country}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {/* INACTIVE */}
                                                    {item.active == false ?
                                                        <div style={{ ...styles.Block, height: 20 }}>
                                                            <p style={{ ...styles.TextXXTiny, color: color.dark_gray }}>{lang.disabled}</p>
                                                        </div>
                                                        : null}
                                                </div>

                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }
                                </div>

                                {/* PAGINATION */}
                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={itemsMax} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeItemsMax(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showCustomer == true ?
                <Customer item={itemSelected} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomerResult.bind()} />
                : null}


            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Customer = (props) => {


    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [overID, setOverID] = useState(-1);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [active, setActive] = useState(true);
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [town, setTown] = useState('');
    const [psc, setPSC] = useState('');
    const [country, setCountry] = useState('');
    const [deliveryCompany, setDeliveryCompany] = useState('');
    const [deliveryName, setDeliveryName] = useState('');
    const [deliverySurname, setDeliverySurname] = useState('');
    const [deliveryStreet, setDeliveryStreet] = useState('');
    const [deliveryStreetNumber, setDeliveryStreetNumber] = useState('');
    const [deliveryTown, setDeliveryTown] = useState('');
    const [deliveryPSC, setDeliveryPSC] = useState('');
    const [deliveryCountry, setDeliveryCountry] = useState('');
    const [ico, setICO] = useState('');
    const [dic, setDIC] = useState('');
    const [icDPH, setICDPH] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [avatar, setAvatar] = useState('');

    // PREDPLATNE
    const [subscriptions, setSubscriptions] = useState(false);
    const [subscriptionSelected, setSubscriptionSelected] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);

    // PLATBY
    const [payments, setPayments] = useState(false);
    const [paymentSelected, setPaymentSelected] = useState(false);
    const [showPayments, setShowPayments] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [itemsMax, setItemsMax] = useState(global.items_max);
    const [paymentSubID, setPaymentSubID] = useState(0);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showInputText, setShowInputText] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 850 ? 850 : width;
    const dialogHeight = height >= global.dialog_max_height ? global.dialog_max_height : height;

    // CONSTANTS
    const tabHeight = itemID == 0 ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;
    const rowHeight = 80;
    const paginationHeight = 50;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.subscriptions },
        { id: 2, enabled: true, label: lang.payments },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (itemID > 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_subscriptions = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_subscriptions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    customer_id: itemID,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setSubscriptions(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_payments = async (page, filter, items_max) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'customer_payments', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    customer_id: itemID,
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setPayments(tmp);

                setPage(page);
                setPages(GetPages(json.count, items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);

                if (data.id == 0) {
                    setItemID(json.ok);
                }
                setRedrawRequired(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_payments_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setActive(item.active == false ? false : true);
        setCompany(item.company);
        setName(item.name);
        setSurname(item.surname);
        setStreet(item.street);
        setStreetNumber(item.street_number);
        setTown(item.town);
        setPSC(item.psc);
        setCountry(item.country);
        setDeliveryCompany(item.delivery_company);
        setDeliveryName(item.delivery_name);
        setDeliverySurname(item.delivery_surname);
        setDeliveryStreet(item.delivery_street);
        setDeliveryStreetNumber(item.delivery_street_number);
        setDeliveryTown(item.delivery_town);
        setDeliveryPSC(item.delivery_psc);
        setDeliveryCountry(item.delivery_country);
        setMobil(item.mobil);
        setEmail(item.email);
        setNote(item.note);
        setAvatar(item.avatar);
        setICO(item.ico);
        setDIC(item.dic);
        setICDPH(item.icdph);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.active = active;
        storeData.company = company;
        storeData.name = name;
        storeData.surname = surname;
        storeData.street = street;
        storeData.street_number = streetNumber;
        storeData.town = town;
        storeData.psc = psc;
        storeData.country = country;
        storeData.delivery_company = deliveryCompany;
        storeData.delivery_name = deliveryName;
        storeData.delivery_surname = deliverySurname;
        storeData.delivery_street = deliveryStreet;
        storeData.delivery_street_number = deliveryStreetNumber;
        storeData.delivery_town = deliveryTown;
        storeData.delivery_psc = deliveryPSC;
        storeData.delivery_country = deliveryCountry;
        storeData.mobil = mobil;
        storeData.email = email;
        storeData.note = note;
        storeData.avatar = avatar;
        storeData.ico = ico;
        storeData.dic = dic;
        storeData.icdph = icDPH;
    }

    const Save = () => {
        setError('');
        var error = false;
        var error_text = lang.required_red;
        var mobil_ = mobil.trim();

        error += IsEmpty(name) == true ? 1 : 0;
        error += IsEmpty(surname) == true ? 1 : 0;
        error += IsEmpty(email) == true ? 1 : 0;

        if (mobil_ != '') {
            if (mobil_[0] != '+') {
                error++;
                error_text = lang.mobil_international;
            }
        }

        if (error == 0) {

            let data = {
                id: itemID,
                active: active,
                company: company.trim(),
                name: name.trim(),
                surname: surname.trim(),
                street: street,
                street_number: streetNumber,
                town: town,
                psc: psc,
                country: country,
                mobil: mobil,
                email: email,
                note: note,
                avatar: avatar,
                ico: ico,
                dic: dic,
                icdph: icDPH,
                delivery_company: deliveryCompany.trim(),
                delivery_name: deliveryName.trim(),
                delivery_surname: deliverySurname.trim(),
                delivery_street: deliveryStreet,
                delivery_street_number: deliveryStreetNumber,
                delivery_town: deliveryTown,
                delivery_psc: deliveryPSC,
                delivery_country: deliveryCompany,
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(error_text);
        }

    }

    const AvatarPress = (typ) => {
        if (typ == true) {
            setSelectPhoto(true);
        } else {
            setAvatar('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            db_subscriptions();
        }
        if (id == 2) {
            db_subscriptions();
            db_payments(0, filter, itemsMax);
        }
    }

    const CheckChanges = () => {
        var changes = 0;

        changes += avatar != storeData.avatar ? 1 : 0;
        changes += active != storeData.active ? 1 : 0;
        changes += company != storeData.company ? 1 : 0;
        changes += name != storeData.name ? 1 : 0;
        changes += surname != storeData.surname ? 1 : 0;
        changes += mobil != storeData.mobil ? 1 : 0;
        changes += email != storeData.email ? 1 : 0;

        changes += street != storeData.street ? 1 : 0;
        changes += streetNumber != storeData.street_number ? 1 : 0;
        changes += town != storeData.town ? 1 : 0;
        changes += psc != storeData.psc ? 1 : 0;
        changes += country != storeData.country ? 1 : 0;

        changes += ico != storeData.ico ? 1 : 0;
        changes += dic != storeData.dic ? 1 : 0;
        changes += icDPH != storeData.icdph ? 1 : 0;
        changes += note != storeData.note ? 1 : 0;

        changes += deliveryCompany != storeData.delivery_company ? 1 : 0;
        changes += deliveryName != storeData.delivery_name ? 1 : 0;
        changes += deliverySurname != storeData.delivery_surname ? 1 : 0;
        changes += deliveryStreet != storeData.delivery_street ? 1 : 0;
        changes += deliveryStreetNumber != storeData.delivery_street_number ? 1 : 0;
        changes += deliveryTown != storeData.delivery_town ? 1 : 0;
        changes += deliveryPSC != storeData.delivery_psc ? 1 : 0;
        changes += deliveryCountry != storeData.delivery_country ? 1 : 0;

        return changes == 0 ? false : true;
    }

    const SubscriptionNew = () => {
        setSubscriptionSelected(false);
        setShowSubscription(true);
    }

    const SubscriptionEdit = (item) => {
        setSubscriptionSelected(item);
        setShowSubscription(true);
    }

    const SubscriptionResult = (redraw_) => {
        setShowSubscription(false);

        if (redraw_ == true) {
            db_subscriptions();
            setRedrawRequired(true);
        }
    }

    const PaymentEdit = (item) => {
        setPaymentSelected(item);
        setShowPayments(true);
    }

    const PaymentResult = (redraw_) => {
        setShowPayments(false);

        if (redraw_ == true) {
            db_payments(page, filter, itemsMax);
            setRedrawRequired(true);
        }
    }

    const ChangePage = (value) => {
        db_payments(value - 1, filter, itemsMax);
    }

    const ChangeItemsMax = (value) => {
        setItemsMax(value);
        db_payments(0, filter, value);
    }

    const TextInput = (typ, data) => {
        setShowInputText(false);
        Debug(data.length);
        Debug(data);
        Debug('-------');
        if (typ == true) {
            if (data.company != undefined) {
                setCompany(data.company);
            }
            if (data.name != undefined) {
                setName(data.name);
            }
            if (data.surname != undefined) {
                setSurname(data.surname);
            }
            if (data.street != undefined) {
                setStreet(data.street);
            }
            if (data.street_number != undefined) {
                setStreetNumber(data.street_number);
            }
            if (data.zip != undefined) {
                setPSC(data.zip);
            }
            if (data.town != undefined) {
                setTown(data.town);
            }
            if (data.ICO != undefined) {
                setICO(data.ICO);
            }
            if (data.DIC != undefined) {
                setDIC(data.DIC);
            }
            if (data.IC_DPH != undefined) {
                setICDPH(data.IC_DPH);
            }
            if (data.mobil != undefined) {
                var tmp = data.mobil[0] == '+' ? data.mobil : '+421' + data.mobil.substring(1, data.mobil.length);
                setMobil(tmp);
            }
            if (data.email != undefined) {
                setEmail(data.email);
            }
        }
    }

    const Copy = () => {
        setDeliveryCompany(company);
        setDeliveryName(name);
        setDeliverySurname(surname);
        setDeliveryStreet(street);
        setDeliveryStreetNumber(streetNumber);
        setDeliveryPSC(psc);
        setDeliveryTown(town);
        setDeliveryCountry(country);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 100 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.customer_new : lang.customer}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {itemID != 0 ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 3} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    <div style={{ ...styles.BlockCenter, height: tabIndex == 0 ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight : dialogHeight - global.dialog_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {tabIndex == 0 ?
                            <>
                                {storeData != false ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * ZAKAZNIK - UDAJE
                                    * =================================================================================================================
                                    *
                                    */
                                    <div>
                                        <FormSpace />
                                        <div style={{ ...styles.Block }}>
                                            <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                            <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                        </div>
                                        <FormSpace />
                                        <div style={{ ...styles.BlockRow }}>
                                            <FormLabel editing={editing} editButton={true} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2 - 50} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                            <div style={{ ...styles.BlockRight, width: 50 }}>
                                                <IconButton onClick={() => setShowInputText(true)} style={{ ...styles.ButtonClose }}>
                                                    <FontAwesomeIcon style={{ width: 16 }} icon={faFileText} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <FormYesNo value={active} editing={editing} title={lang.customer_active} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setActive(value)} />
                                        <FormText redraw value={company} editing={editing} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCompany(txt)} />
                                        <FormText require redraw value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                        <FormText require redraw value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />
                                        <FormText redraw phone value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                                        <FormNote title={lang.mobil_international} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                        <FormText require redraw value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />

                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={false} title={lang.address} icon={faLocationDot} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        <FormText redraw value={street} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreet(txt)} />
                                        <FormText redraw value={streetNumber} editing={editing} title={lang.street_number} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setStreetNumber(txt)} />
                                        <FormText redraw value={town} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setTown(txt)} />
                                        <FormText redraw value={psc} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPSC(txt)} />
                                        <FormText redraw value={country} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCountry(txt)} />

                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={false} title={lang.firm_data} column1={column1} column2={column2} icon={faBook} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                        <FormText redraw value={ico} editing={editing} title={lang.ico} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICO(txt)} />
                                        <FormText redraw value={dic} editing={editing} title={lang.dic} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDIC(txt)} />
                                        <FormText redraw value={icDPH} editing={editing} title={lang.ic_dph} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setICDPH(txt)} />

                                        <FormSpace />
                                        <div style={{ ...styles.BlockRow }}>
                                            <FormLabel editing={editing} editButton={false} title={lang.delivery_address} icon={faPaperPlane} column1={column1} column2={column2 - 50} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                            <div style={{ ...styles.BlockRight, width: 50 }}>
                                                <IconButton onClick={() => Copy()} style={{ ...styles.ButtonClose }}>
                                                    <FontAwesomeIcon style={{ width: 12 }} icon={faCopy} />
                                                </IconButton>
                                            </div>
                                        </div>

                                        <FormText redraw value={deliveryCompany} editing={editing} title={lang.company_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryCompany(txt)} />
                                        <FormText redraw value={deliveryName} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryName(txt)} />
                                        <FormText redraw value={deliverySurname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliverySurname(txt)} />
                                        <FormSpace />
                                        <FormText redraw value={deliveryStreet} editing={editing} title={lang.street} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryStreet(txt)} />
                                        <FormText redraw value={deliveryStreetNumber} editing={editing} title={lang.street_number} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryStreetNumber(txt)} />
                                        <FormText redraw value={deliveryTown} editing={editing} title={lang.town} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryTown(txt)} />
                                        <FormText redraw value={deliveryPSC} editing={editing} title={lang.psc} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryPSC(txt)} />
                                        <FormText redraw value={deliveryCountry} editing={editing} title={lang.state} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDeliveryCountry(txt)} />

                                        <FormSpace />
                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />


                                        <FormSpace height={global.empty_padding} />

                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </>
                            : null}

                        {tabIndex == 1 ?
                            /*
                            *
                            * =================================================================================================================
                            * PREDPLATNE
                            * =================================================================================================================
                            *
                            */

                            <div style={{ ...styles.BlockCenter, width: '94%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: 60 }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.subscription_list}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: 60 }}>
                                        <ButtonNew icon={faPlusCircle} menuItems={false} label={lang.subscription_new} theme={props.theme} func={SubscriptionNew.bind()} />
                                    </div>
                                </div>

                                {subscriptions != false ? subscriptions.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => SubscriptionEdit(item)}
                                        style={{
                                            ...styles.Block,
                                            borderRadius: global.items_radius,
                                            backgroundColor: item.active == false ? color.disabled : color.white,
                                            cursor: 'pointer',
                                            marginTop: 5,
                                            width: '98%',
                                            height: rowHeight,
                                            borderLeft: item.active == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '94%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: '50%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <TextLine text={item.magazine} fontSize={global.font_xsmall} fontWeight={'600'} marginLeft={5} color={color.black} />
                                                <TextLine text={item.subscription} fontSize={global.font_xsmall} marginLeft={5} color={color.black} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.subscriptions_period}</p>
                                                <p style={{ ...styles.TextTiny }}>{GetDatum2(item.date_start)} - {GetDatum2(item.date_end)}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.subscription_quantity}: {item.quantity}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 120, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall }}>{GetPrice(parseFloat(parseFloat(item.price) * parseFloat(item.discount)).toFixed(2))} {lang.money}</p>
                                                {parseFloat(item.discount) < 1 ?
                                                    <Chip size='small' style={{ marginTop: 5 }} label={lang.subscription_discount_ + ' ' + CoeficientToPercentage(item.discount) + '%'} />
                                                    : null}
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <div style={{ ...styles.Block, paddingTop: 50 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }
                                <FormSpace height={global.empty_padding} />

                            </div>
                            : null}

                        {tabIndex == 2 ?
                            /*
                            *
                            * =================================================================================================================
                            * PLATBY
                            * =================================================================================================================
                            *
                            */

                            <div id={'id_payments_start'} style={{ ...styles.BlockCenter, width: '94%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '60%', height: 60 }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.payment_list}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '40%', height: 60 }}>
                                    </div>
                                </div>

                                {payments != false ? payments.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => PaymentEdit(item)}
                                        style={{
                                            ...styles.Block,
                                            borderRadius: global.items_radius,
                                            backgroundColor: item.paid == false ? color.lighteen_red : color.white,
                                            cursor: 'pointer',
                                            marginTop: 5,
                                            width: '98%',
                                            height: rowHeight,
                                            borderLeft: item.active == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '94%' }}>
                                            <div style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight, justifyContent: 'center', borderRight: color.border }}>
                                                <p style={{ ...styles.TextTiny }}>{GetDatum2(item.created)}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '40%', minHeight: rowHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextXSmall }}><b>{item.magazine}</b></p>
                                                <p style={{ ...styles.TextXSmall }}>{item.subscription}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.payment_code}: {item.code}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall }}>{GetPrice(item.price)} {lang.money}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 130, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.paid == false ? item.email_sended == true ?
                                                    <Chip size='small' label={lang.payment_email_sended_} style={{ backgroundColor: color.white, color: color.dark_red }} />
                                                    : null : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 110, marginLeft: 20, minHeight: rowHeight, justifyContent: 'center' }}>
                                                {item.paid == true ?
                                                    <>
                                                        <Chip size='small' label={lang.payment_paid} style={{ backgroundColor: color.dark_green, color: color.white }} />
                                                        <p style={{ ...styles.TextXTiny, marginTop: 2 }}>{GetDatum2(item.date)}</p>
                                                    </>
                                                    :
                                                    <Chip size='small' label={lang.payment_not_paid} style={{ backgroundColor: color.dark_red, color: color.white }} />
                                                }
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <div style={{ ...styles.Block, paddingTop: 50 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }

                                {/* PAGINATION */}
                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 200 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                                <SelectInput theme={props.theme} enabled={true} lang={lang} value={itemsMax} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeItemsMax(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <FormSpace height={global.empty_padding} />

                            </div>
                            : null}


                    </div>
                    {tabIndex == 0 ?
                        <>
                            <div style={{ ...styles.Block, height: errorHeight }}>
                                <FormError small margin={0} error={error} theme={props.theme} />
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {tabIndex == 0 && editing == true && CheckChanges() == true ?
                                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                        : null}
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                                </div>
                            </div>
                        </>
                        : null}
                </div >

                {showInputText == true ?
                    <CustomerText user={props.user} lang={lang} theme={props.theme} radius={props.radius} token={props.token} func={TextInput.bind()} />
                    : null}

                {showPayments == true ?
                    <CustomerPayment customerID={itemID} subscriptions={subscriptions} item={paymentSelected} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={PaymentResult.bind()} />
                    : null}

                {showSubscription == true ?
                    <CustomerSubscription customerID={itemID} item={subscriptionSelected} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={SubscriptionResult.bind()} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.avatars} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* PREDPLATNE ZAKAZNIKA -> UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const CustomerSubscription = (props) => {


    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [customerID, setCustomerID] = useState(props.customerID);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [invoiceCreated, setInvoiceCreated] = useState(false);
    const [active, setActive] = useState(true);
    const [magazineID, setMagazineID] = useState(-1);
    const [subscriptionID, setSubscriptionID] = useState(-1);
    const [quantity, setQuantity] = useState('1');
    const [discount, setDiscount] = useState('1');
    const [dateStart, setDateStart] = useState(Today());
    const [dateEnd, setDateEnd] = useState(Today());
    const [note, setNote] = useState('');

    // ZOZNAMY
    const [magazines, setMagazines] = useState(false);
    const [subscriptions, setSubscriptions] = useState(false);
    const [months, setMonths] = useState(12);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 580 ? 580 : width;
    const dialogHeight = height >= 780 ? 780 : height;

    // CONSTANTS
    const tabHeight = 0;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 160;
    const column2 = dialogWidth - 40 - column1;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            db_data();

            if (itemID > 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_subscription', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_data = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'subscription_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setMagazines(json.magazines);
                setSubscriptions(json.subscriptions);

                if (itemID == 0) {
                    if (json.magazines != false) {
                        if (json.magazines.length == 1) {
                            setMagazineID(json.magazines[0].id);
                        }
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_subscription_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (data.id == 0) {
                    setItemID(json.ok);
                }

                if (data.invoice_created != undefined) {
                    setInvoiceCreated(false);
                } else {
                    setShowOK(true);
                }

                setRedrawRequired(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_payment_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_payment_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
                setRedrawRequired(true);

            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setInvoiceCreated(item.invoice_created == false ? false : true);
        setActive(item.active == false ? false : true);
        setMagazineID(item.magazine_id);
        setSubscriptionID(item.subscription_id);
        setQuantity(item.quantity);
        setDiscount(item.discount);
        setDateStart(item.date_start);
        setDateEnd(item.date_end)
        setNote(item.note);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.active = active;
        storeData.magazine_id = magazineID;
        storeData.subscription_id = subscriptionID;
        storeData.quantity = quantity;
        storeData.discount = discount;
        storeData.date_start = dateStart;
        storeData.date_end = dateEnd;
        storeData.note = note;

    }

    const Save = () => {
        setError('');
        var error = false;

        error += magazineID < 1 ? 1 : 0;
        error += subscriptionID < 1 ? 1 : 0;
        error += IsEmpty(discount) == true ? 1 : 0;

        if (error == 0) {

            let data = {
                id: itemID,
                active: active,
                customer_id: customerID,
                magazine_id: magazineID,
                subscription_id: subscriptionID,
                quantity: quantity,
                discount: discount,
                date_start: dateStart,
                date_end: dateEnd,
                note: note,
            };

            StoreEditing();
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const CheckChanges = () => {
        var changes = 0;

        changes += active != storeData.active ? 1 : 0;
        changes += magazineID != storeData.magazine_id ? 1 : 0;
        changes += subscriptionID != storeData.subscription_id ? 1 : 0;
        changes += quantity != storeData.quantity ? 1 : 0;
        changes += discount != storeData.discount ? 1 : 0;
        changes += dateStart != storeData.date_start ? 1 : 0;
        changes += dateEnd != storeData.date_end ? 1 : 0;
        changes += note != storeData.note ? 1 : 0;
        return changes == 0 ? false : true;
    }

    const GeneratePayment = () => {
        var price = 0;
        var tmp = subscriptions.find(x => x.id == subscriptionID);
        if (tmp != undefined) {
            price = tmp.price;
        }

        var data = {
            id: 0,
            enabled: true,
            customer_id: customerID,
            subs_id: itemID,
            code: '',
            date: Today(),
            date_end: dateEnd,
            date_new_start: dateStart,
            date_new_end: dateEnd,
            price: price,
            quantity: quantity,
            sum: (parseFloat(price) * parseInt(quantity) * parseFloat(discount)).toFixed(2),
            paid: false
        };

        Debug(data);
        db_payment_update(data);
    }

    const DeleteInvoiceCreated = () => {

        let data = {
            id: itemID,
            invoice_created: false,
            invoice_created: false,
        };

        db_update(data);
    }

    const ChangeSubscription = (id) => {
        setSubscriptionID(id);

        var value = 12;
        var tmp = subscriptions.find(x => x.id == id);
        if (tmp != undefined) {
            value = tmp.months;
        }
        setMonths(value);
    }

    const ChangeDateStart = (txt) => {
        setDateStart(txt);
        var date_end = addMonthsToDate(txt, parseInt(months));
        Debug(txt);
        Debug(date_end);
        setDateEnd(date_end);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 150 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.subscription_new : lang.subscriptions}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: tabIndex == 0 ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight : dialogHeight - global.dialog_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {storeData != false ?
                            <div>
                                <FormSpace />
                                <FormYesNo value={active} editing={editing} title={lang.subscription_active} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setActive(value)} />

                                <FormSpace />
                                <FormSelect require value={magazineID} items={magazines} field={'name'} editing={editing} title={lang.magazine} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => { setMagazineID(id); setSubscriptionID(-1) }} />
                                <FormSelect require value={subscriptionID} items={magazineID == 0 ? false : subscriptions != false ? subscriptions.filter(x => x.magazine_id == magazineID) : false} field={'name'} editing={editing} title={lang.subscriptions} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeSubscription(id)} />
                                <FormText require redraw numeric value={quantity} editing={editing} title={lang.subscription_quantity} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuantity(txt)} />
                                <FormText require redraw percentage value={discount} editing={editing} title={lang.subscription_discount} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDiscount(txt)} />
                                <FormNote title={lang.subscription_discount_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />

                                <FormText redraw date value={dateStart} editing={editing} title={lang.subscription_date_start} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeDateStart(txt)} />
                                <FormText redraw date value={dateEnd} editing={editing} title={lang.subscription_date_end} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateEnd(txt)} />

                                <FormSpace />
                                <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                <FormSpace />

                                {invoiceCreated == true ?
                                    <>
                                        <Tooltip title={lang.subscription_created_chip} PopperProps={{ style: { zIndex: 31001 } }} >
                                            <Chip onDoubleClick={() => DeleteInvoiceCreated()} size='medium' label={<p style={{ ...styles.TextTiny, color: color.white }}>{lang.payment_generated_}</p>} style={{ backgroundColor: color.dark_red, cursor: 'pointer' }} />
                                        </Tooltip>
                                        <FormSpace />
                                    </>
                                    : null}

                                {itemID > 0 && invoiceCreated == false ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginBottom: 5 }}>{lang.subscription_generate_payment_note}</p>
                                        <DeleteButton outlined ask button bold label={lang.subscription_generate_payment} icon={faPlusCircle} position={'top'} text={lang.subscription_generate_payment_} text_note={lang.subscription_generate_payment_ask} theme={props.theme} lang={props.lang} func={() => GeneratePayment()} />
                                    </div>
                                    : null}

                                <FormSpace height={60} />

                            </div>
                            :
                            <Stack style={{ ...styles.Block }} spacing={1}>
                                <FormSpace />
                                <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                            </Stack>
                        }
                    </div>

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true && CheckChanges() == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* PLATBA ZAKAZNIKA -> UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const CustomerPayment = (props) => {


    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [customerID, setCustomerID] = useState(0);
    const [subID, setSubID] = useState(0);
    const [date, setDate] = useState(Today());
    const [dateEnd, setDateEnd] = useState(Today());            // koniec povodneho predplatneho
    const [dateNewStart, setDateNewStart] = useState(Today());  // zaciatok noveho predplatného
    const [dateNewEnd, setDateNewEnd] = useState(Today());      // koniec noveho predplatného
    const [price, setPrice] = useState('0');
    const [quantity, setQuantity] = useState('1');
    const [sum, setSum] = useState('0');
    const [code, setCode] = useState('');
    const [paid, setPaid] = useState(false);
    const [expired, setExpired] = useState(0);
    const [sms, setSMS] = useState(0);

    // DATA
    const [magazine, setMagazine] = useState(false);
    const [subscription, setSubscription] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [email, setEmail] = useState(false);
    const [showEmailPreview, setShowEmailPreview] = useState(false);
    const [showSMS, setShowSMS] = useState(false);
    const [showSMSSend, setShowSMSSend] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 920 ? 920 : height;

    // CONSTANTS
    const tabHeight = 0;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 200;
    const column2 = dialogWidth - 40 - column1;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (itemID > 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_payment', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;

                    if (item.paid == false) {
                        item.date = date;
                    }

                    setStoreData(item);

                    AssignData(item);

                    setMagazine(json.magazine);
                    setSubscription(json.subscription);
                    setCustomer(json.customer);
                    setEmail(json.email);
                    setSMS(json.sms);

                    if (item.paid == true) {
                        setEditing(false);
                    }

                    if (json.subscription != false && item.paid == false) {
                        CreatePeriod(Today(), item.date_end, json.subscription.months);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_payment_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);

                if (data.id == 0) {
                    setItemID(json.ok);
                }
                setRedrawRequired(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_resend = async () => {
        setBusy(true);
        Debug(itemID);
        try {
            const response = await fetch(
                global.db_url + 'customer_payment_resend', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    payment_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setRedrawRequired(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }


    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setCustomerID(item.customer_id);
        setSubID(item.subs_id);
        if (item.paid == true) {
            setDate(item.date);
        }
        setPrice(item.price);
        setPaid(item.paid == false ? false : true);
        setDateEnd(item.date_end);
        setDateNewStart(item.date_new_start);
        setDateNewEnd(item.date_new_end);
        setQuantity(item.quantity);
        setSubID(item.subs_id);
        setSum(item.sum);
        setCode(item.code);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.customer_id = customerID;
        storeData.subs_id = subID;
        storeData.date = date;
        storeData.price = price;
        storeData.paid = paid;
        storeData.date_new_start = dateNewStart;
        storeData.date_new_end = dateNewEnd;
        storeData.quantity = quantity;
        storeData.sum = sum;
        storeData.code = code;
    }

    const Save = () => {
        setError('');
        var error = false;


        error += IsEmpty(code) == true ? 1 : 0;
        error += IsEmpty(quantity) == true ? 1 : 0;
        error += IsEmpty(price) == true ? 1 : 0;
        error += IsEmpty(sum) == true ? 1 : 0;

        if (error == 0) {

            var data = {
                id: itemID,
                customer_id: customerID,
                subs_id: subID,
                code: code,
                date: date,
                price: price,
                quantity: quantity,
                sum: sum,
                paid: paid,
                date_new_start: dateNewStart,
                date_new_end: dateNewEnd,
            };
            if (email != false) {
                if (paid == false && email.sended == false) {
                    data.email_modify = true;
                }
            }

            if (paid == true) {
                data.payment = true;
            }

            StoreEditing();
            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const CheckChanges = () => {
        var changes = 0;

        changes += code != storeData.code ? 1 : 0;
        changes += date != storeData.date ? 1 : 0;
        changes += price != storeData.price ? 1 : 0;
        changes += quantity != storeData.quantity ? 1 : 0;
        changes += sum != storeData.sum ? 1 : 0;
        changes += paid != storeData.paid ? 1 : 0;
        changes += dateNewStart != storeData.date_new_start ? 1 : 0;
        changes += dateNewEnd != storeData.date_new_end ? 1 : 0;

        return changes == 0 ? false : true;
    }


    const CreatePeriod = (date_payment_, date_end_, months) => {
        var date_payment = GetMiliseconds(date_payment_);
        var date_end = GetMiliseconds(date_end_);
        var date_reserve = GetMiliseconds(AddDays(date_end_, 30));

        if (date_payment <= date_end) {
            // ZAPLATENE PRED KONCOM OBDOBIA
            setDateNewStart(date_end_);
            let newDate = addMonthsToDate(date_end_, parseInt(months));
            setDateNewEnd(newDate);
            setExpired(0);
            Debug('PREDPLATNE OK');

        } else if (date_payment <= date_reserve) {
            // ZAPLATENE 30 DNI PO SKONCENI OBDOBIA
            setDateNewStart(date_end_);
            let newDate = addMonthsToDate(date_end_, parseInt(months));
            setDateNewEnd(newDate);
            setExpired(1);
            Debug('PREDPLATNE 30 DNI');
        } else {
            // ZAPLATENE PO SPLATNOSTI
            setDateNewStart(date_payment_);
            let newDate = addMonthsToDate(date_payment_, parseInt(months));
            setDateNewEnd(newDate);
            setExpired(2);
            Debug('PREDPLATNE PO SPLATNOSTI');
        }

    }

    const ChangeDate = (date_) => {
        setDate(date_);
        CreatePeriod(date_, dateEnd, subscription.months);
    }

    const PaymentDelete = () => {
        var data = {
            id: itemID,
            enabled: false,
            delete: true,
            subs_id: subID,
        };

        db_update(data);
    }

    const EmailResend = () => {
        db_resend();
    }

    const SMSSend = () => {
        setShowSMSSend(true);
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 200 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faDollar} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.payment}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: tabIndex == 0 ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight : dialogHeight - global.dialog_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {storeData != false && subscription != false && magazine != false && customer != false ?
                            <div style={{ ...styles.Block }}>
                                <div style={{ ...styles.Block, backgroundColor: color.white, paddingTop: 10, paddingBottom: 10 }}>

                                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft, width: 180 }}>
                                            <Avatar picture image={subscription.avatar} size={136} theme={props.theme} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '73%' }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{customer.company}</p>
                                            <p style={{ ...styles.TextXSmall }}>{customer.name} {customer.surname}</p>
                                            <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{customer.street} {customer.streetNumber}</p>
                                            <p style={{ ...styles.TextXSmall }}>{customer.psc} {customer.town} {customer.country}</p>
                                            <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.email}: {customer.email}</p>
                                            <p style={{ ...styles.TextXSmall }}>{lang.mobil}: {customer.mobil}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', marginTop: 8 }}>{magazine.name}</p>
                                    <p style={{ ...styles.TextTiny }}>{subscription.name}</p>
                                    {editing == true ?
                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.subscription_date_end_actual}: {GetDatum2(dateEnd)}</p>
                                        : null}
                                </div>
                                <FormSpace />
                                <FormYesNo value={paid} editing={editing} title={lang.payment_paid} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setPaid(value)} />
                                <FormSpace />
                                <FormText require redraw numeric value={code} editing={editing} title={lang.payment_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode(txt)} />
                                <FormText require redraw numeric value={quantity} editing={editing} title={lang.subscription_quantity} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setQuantity(txt)} />
                                <FormText require redraw numeric value={price} editing={editing} title={lang.subscription_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPrice(txt)} />
                                <FormText require redraw numeric calc value={sum} editing={editing} title={lang.payment_sum} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSum(txt)} />
                                <FormText redraw date value={date} editing={editing} title={lang.payment_date} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => ChangeDate(txt)} />
                                <FormNote title={expired == 0 ? lang.payment_in_time : expired == 1 ? lang.payment_expired_30 : lang.payment_expired} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                <Line color={'#D0D0D0'} theme={props.theme} marginBottom={8} />
                                <div style={{ ...styles.BlockRow, width: column1 + column2 + 10 }}>
                                    <FormText redraw date value={dateNewStart} editing={editing} title={lang.subscriptions_period} column1={column1} column2={editing == true ? column2 / 2 - 10 : 85} width={column2 / 2 - 10} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateNewStart(txt)} />
                                    <div style={{ ...editing == true ? styles.Block : styles.BlockLeft, width: 20, height: 46 }}>
                                        <FontAwesomeIcon style={{ height: 14, color: color.black }} icon={faMinus} />
                                    </div>
                                    <FormText redraw date value={dateNewEnd} editing={editing} column1={0} column2={column2 / 2 - 10} width={editing == true ? column2 / 2 - 10 : column2 - 85 - 20} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateNewEnd(txt)} />
                                </div>
                                <Line color={'#D0D0D0'} theme={props.theme} marginTop={10} marginBottom={8} />
                                <div style={{ ...styles.BlockRow, width: column1 + column2 + 10 }}>
                                    <div style={{ ...styles.Block }}>
                                        <FormText redraw button value={email != false ? email.sended == true ? lang.payment_email_sended : lang.payment_email_waiting : ''} editing={false} title={lang.payment_email_status} column1={column1} column2={email != false ? email.sended == true && paid == false ? column2 - 50 - 180 : column2 - 50 : column2 - 50} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                    </div>
                                    <div style={{ ...styles.Block, height: 50 }}>
                                        <IconButton onClick={() => setShowEmailPreview(true)} style={{ ...styles.ButtonIcon }}>
                                            <FontAwesomeIcon style={{ width: 14 }} icon={faMagnifyingGlass} />
                                        </IconButton>
                                    </div>
                                    {email != false ? email.sended == true && paid == false ?
                                        <div style={{ ...styles.Block, width: 180, height: 50 }}>
                                            <DeleteButton outlined ask button bold label={lang.payment_email_resend} icon={faPaperPlane} position={'top'} text={lang.payment_email_resend + '?'} text_note={lang.payment_email_resend_text} theme={props.theme} lang={props.lang} func={() => EmailResend()} />
                                        </div>
                                        : null : null}
                                </div>
                                {email != false ? email.sended == true && CheckChanges() ?
                                    <FormNote title={lang.payment_email_no_modify} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    : null : null}

                                <div style={{ ...styles.BlockRow, width: column1 + column2 + 10 }}>
                                    <div style={{ ...styles.Block }}>
                                        <FormText redraw button value={sms} editing={false} title={lang.sms_notify_count} column1={column1} column2={column2 - 50 - 180} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => Debug(txt)} />
                                    </div>
                                    <div style={{ ...styles.Block, height: 50 }}>
                                        {parseInt(sms) > 0 ?
                                            <IconButton onClick={() => setShowSMS(true)} style={{ ...styles.ButtonIcon }}>
                                                <FontAwesomeIcon style={{ width: 14 }} icon={faMagnifyingGlass} />
                                            </IconButton>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 180, height: 50 }}>
                                        {editing == true ? customer.mobil.trim() != '' ?
                                            <Button onClick={() => SMSSend()} style={{ ...styles.ButtonOutlined }}>{lang.sms_notify_send}</Button>
                                            :
                                            null : null}
                                    </div>
                                </div>{editing == true ? customer.mobil.trim() == '' ?
                                    <FormNote title={lang.sms_mobil_missing} column1={column1} column2={column2} marginTop={0} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                    : null : null}

                                <FormSpace />

                            </div>
                            :
                            <Stack style={{ ...styles.Block }} spacing={1}>
                                <FormSpace />
                                <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                            </Stack>
                        }
                    </div>

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, width: undefined, marginRight: 20 }}>
                                <DeleteButton ask text={lang.payment_delete_} tooltip={lang.payment_delete} theme={props.theme} lang={props.lang} icon={faTrash} func={() => PaymentDelete()} />
                            </div>
                            {editing == true && CheckChanges() == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showSMS == true ?
                    <DialogSMSLogs customer={customer} payment_id={itemID} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowSMS(false)} />
                    : null}

                {showSMSSend == true ?
                    <DialogSMS customer={customer} code={code} sum={sum} payment_id={itemID} subscription={subscription} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowSMSSend(false)} />
                    : null}

                {showEmailPreview == true ?
                    <EmailPreview email={email} locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowEmailPreview(false)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

export const CustomerText = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <CustomerText rows={1} title={'Informácia'} text={'Text informácie'} icon={faQuestion} button={props.lang.close} theme={props.theme} radius={props.radius} func={DialogEnterTextResult.bind(this)} />

            --------------------------------------------------------
            rows    - pocet riadkov

            --------------------------------------------------------

            const DialogEnterTextResult = (typ, value) => {
                console.log(value)
            }
            */

    const [isBusy, setBusy] = useState(false);

    const lang = props.lang;
    const [text, setText] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 480;

    let column1 = 150;
    let column2 = dialogWidth - column1 - 40;

    let { func } = props;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];

    var running = false;
    useEffect(() => {

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);


        return () => {
        };

    }, []);

    const db_open_ai = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'openai_function', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    question: 'Extraktuj údaje z nasledujúceho textu: ' + text
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);
            if (json.ok > 0) {
                if (json.answer != '') {

                    var customer = JSON.parse(json.answer.arguments);
                    func(true, customer);

                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(true, text);
    }

    const Close = () => {
        func(false, false);
    }

    const Save = () => {
        /*
        const lines = text.split('\n');
        var customer = [];

        var n = 0;
        lines.forEach(line => {
            let result = line;
            if (line != '' && line != 'E-mailová adresa:' && line != 'Telefón:') {
                if (line.substring(0, 3) == 'IČO' || line.substring(0, 3) == 'DIC') {
                    result = line.substring(5, line.length);
                }
                if (line.substring(0, 6) == 'IČ DPH') {
                    result = line.substring(8, line.length);
                }
                customer.push(result);
            }

            n++;
        });

        func(true, customer);
        */
        db_open_ai();
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFileText} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.ai_customer_text}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>
                        <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.ai_customer_value}</p>
                        <TextField
                            inputRef={inputRef}
                            value={text}
                            onInput={e => setText(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            multiline={true}
                            rows={10}
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Press();
                                }
                            }}
                            InputProps={{
                                style: { backgroundColor: global.theme_white },
                            }}
                            variant="outlined"
                        />
                        <p style={{ ...styles.TextXSmall, marginTop: 5 }}>{lang.ai_customer_note}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, color: color.white, marginRight: 20 }}>{lang.ai_exe}</Button>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, color: color.white }}>{lang.cancel}</Button>
                    </div>

                </div>
            </Paper>

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Backdrop>
    );
}


