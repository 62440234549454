import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { FormSpace, FormText, Icon, FormLabel, Loading, Menu, MenuTitle, MenuTop, TemplateWindow, Line } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faB, faBook, faCakeCandles, faCalendarCheck, faCalendarDays, faCog, faDollar, faInfo, faInfoCircle, faList, faListCheck, faP, faQuestion, faRefresh, faShoppingBag, faShoppingBasket, faShoppingCart, faSms, faTriangleExclamation, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { Debug } from './functions';
import { PieChart } from '@mui/x-charts/PieChart';
import { Coronavirus } from '@mui/icons-material';

export const Desktop = (props) => {

    const color = global.themes[props.theme];

    // VALUES
    const [customers, setCustomers] = useState('');
    const [customersTotal, setCustomersTotal] = useState('');
    const [subscriptions, setSubscriptions] = useState('');
    const [payments, setPayments] = useState('');
    const [yearPayments, setYearPayments] = useState('');
    const [yearPaymentsTotal, setYearPaymentsTotal] = useState('');
    const [emails, setEmails] = useState('');
    const [credits, setCredits] = useState('');
    const [sms, setSMS] = useState('');

    const [width, setWidth] = useState(props.width);

    let { func } = props;
    var lang = props.lang;
    var running = false;

    const offset = 16;

    useEffect(() => {

        if (running == false) {
            db_get();
            running = true;
        }

        setWidth(props.width);

    }, [props.employee_id, props.user, props.width, props.country])

    const db_get = async () => {
        try {
            const response = await fetch(
                global.db_url + 'info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                })
            })

            const json = await response.json();
            Debug('DASHOBOARD', 'cyan');
            Debug(json);
            if (json.ok > 0) {
                setCustomers(json.customers);
                setCustomersTotal(json.customers_total);
                setSubscriptions(json.subscriptions);
                setPayments(json.payments);
                setEmails(json.emails);
                setCredits(json.credits);
                setYearPaymentsTotal(json.year_payments_total);
                setYearPayments(json.year_payments);
                setSMS(json.sms);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const db_sms = async () => {
        try {
            const response = await fetch(
                global.db_url + 'sms_credits', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setCredits(json.credits);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const InfoResult = (id) => {
        if (id == 5) {
            // REFRESH SMS KREDITU
            db_sms();
        }
    }

    return (
        <div style={{ ...styles.BlockCenter, width: props.width, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

            <div style={{ ...styles.Block, flexDirection: props.isSmall ? 'column' : 'row', flexWrap: 'wrap', width: props.width }}>
                <DesktopBoxInfo id={1} color={color.dashboard_blue} icon={faUser} title={lang.info_customer} value={customers} note={lang.info_customer_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                <DesktopBoxInfo id={2} color={color.dashboard_green} icon={faBook} title={lang.info_subscriptions} value={subscriptions} note={lang.info_subscriptions_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                <DesktopBoxInfo id={3} color={color.dashboard_red} icon={faDollar} title={lang.info_payments} value={payments} note={lang.info_payments_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                <DesktopBoxInfo id={4} color={color.dashboard_orange} icon={faAt} title={lang.info_emails} value={emails} note={lang.info_emails_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                <DesktopBoxInfo id={6} color={color.dashboard_blue_light} icon={faSms} title={lang.info_sms_to_send} value={sms} note={lang.info_sms_to_send_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                <DesktopBoxInfo id={5} refresh color={color.dashboard_violet} icon={faSms} title={lang.info_sms} value={credits} note={lang.info_sms_note} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
            </div>

            <div style={{ ...styles.Block, flexDirection: props.isSmall ? 'column' : 'row', flexWrap: 'wrap', width: props.width }}>
                <DesktopBoxPie title={lang.info_customer_stars} pie_value_1={customersTotal} pie_value_2={customers} pie_text_1={lang.info_customer_inactive} pie_text_2={lang.info_customer_active} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                <DesktopBoxPie title={lang.info_payments_year_label} pie_value_1={yearPaymentsTotal} pie_value_2={yearPayments} pie_text_1={lang.info_payments_year_total} pie_text_2={lang.info_payments_year} theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
            </div>
            {global.testing == true ?
                <DesktopTesting theme={props.theme} system={props.system} settings={props.settings} user={props.user} lang={props.lang} language={props.language} offset={offset} radius={props.radius} isSmall={props.isSmall} isMedium={props.isMedium} width={props.width - props.offset * 2} token={props.token} func={InfoResult.bind(this)} />
                : null}
            <FormSpace />
        </div >
    );
}

// ************************************************************************************************************************************************************************************
// TEMPLATES
// ************************************************************************************************************************************************************************************

export const DesktopBoxHalf = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(props.half == true ? props.width / 2 - props.offset : props.width - props.offset);
    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    const labelHeight = 40;

    useEffect(() => {
        if (running == false) {
            setWidth(props.half == true ? props.width / 2 - props.offset : props.width - props.offset);

            running = true;
        }
    }, [props.width])

    const db_get = async (page, filter, items_max) => {
        try {
            const response = await fetch(
                global.db_url + 'customers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <Paper elevation={3} style={{ ...styles.BlockCenter, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius }} >
            <div style={{ ...styles.BlockRow, height: global.dashboard_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color_light }}>
                <div style={{ ...styles.Block, width: 60, height: global.dashboard_bar_height }}>
                    <Icon theme={props.theme} icon={faDollar} />
                </div>
                <div style={{ ...styles.Block, width: '95%', height: global.dashboard_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{lang.payments}</p>
                </div>
            </div>

            <div style={{ ...styles.Block, width: '96%', height: labelHeight, borderBottom: color.border }}>
                <div style={{ ...styles.BlockLeft }}>
                    <p style={{ ...styles.TextTiny }}>{lang.payment_not_paid_list}</p>
                </div>

            </div>

            <div style={{ ...styles.BlockCenter, width: width, height: height - global.dashboard_bar_height - labelHeight - 2, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* BODY */}
            </div>
        </Paper>
    );
}


export const DesktopBoxLarge = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(props.width - props.offset);
    const height = 200;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    const labelHeight = 40;

    useEffect(() => {
        if (running == false) {
            setWidth(props.width - props.offset)

            running = true;
        }
    }, [props.width])


    const db_get = async (page, filter, items_max) => {
        try {
            const response = await fetch(
                global.db_url + 'customers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <Paper elevation={3} style={{ ...styles.BlockCenter, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius }} >
            <div style={{ ...styles.BlockRow, height: global.dashboard_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color_light }}>
                <div style={{ ...styles.Block, width: 60, height: global.dashboard_bar_height }}>
                    <Icon theme={props.theme} icon={faUser} />
                </div>
                <div style={{ ...styles.Block, width: '95%', height: global.dashboard_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{lang.subscriptions}</p>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, width: width, height: height - global.dashboard_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* BODY */}

            </div>
        </Paper>
    );
}


export const DesktopBoxInfo = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(240);
    const height = 100;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width, props.value])


    return (
        <Paper elevation={3} style={{ ...styles.Block, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius, backgroundColor: props.color }} >
            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.Block, width: 50, height: height }}>
                    <FontAwesomeIcon style={{ height: 30, color: '#00000040' }} icon={props.icon} />
                </div>
                <div style={{ ...styles.Block, width: width - 50 }}>
                    <div style={{ ...styles.Block, height: 30 }}>
                        <p style={{ ...styles.TextXSmall, color: color.black }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: width - 50 - 20, height: height - 60, backgroundColor: '#FFFFFF70', borderRadius: 10 }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, width: '90%' }}>
                                <p style={{ ...styles.TextXLarge, color: color.black, fontWeight: '600' }}>{props.value}</p>
                            </div>
                            {props.refresh == true ?
                                <div style={{ ...styles.BlockLeft, width: 50 }}>
                                    <IconButton onClick={() => func(props.id)} style={{ ...styles.ButtonIconThemed }}>
                                        <FontAwesomeIcon style={{ width: 14 }} icon={faRefresh} />
                                    </IconButton>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: 30 }}>
                        <p style={{ ...styles.TextXTiny, color: '#00000070' }}>{props.note}</p>
                    </div>
                </div>
            </div>

        </Paper>
    );
}


export const DesktopBoxPie = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(240 * 2 + props.offset);
    const height = 220;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {

            running = true;
        }
    }, [props.width, props.pie_value_1, props.pie_value_2])


    return (
        <Paper elevation={3} style={{ ...styles.Block, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius, backgroundColor: color.dashboard }} >
            <div style={{ ...styles.Block, height: 40, borderBottom: color.border }}>
                <p style={{ ...styles.TextXSmall }}>{props.title}</p>
            </div>
            <div style={{ ...styles.Block, width: undefined, height: height - 40 }}>
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: props.pie_value_1, label: props.pie_text_1 },
                                { id: 1, value: props.pie_value_2, label: props.pie_text_2 },
                            ],
                        },
                    ]}
                    slotProps={{
                        legend: {
                            padding: 0,
                            //hidden: true
                        },
                    }}
                    margin={{
                        left: -180,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                    width={360}
                    height={160}
                />
            </div>
        </Paper>
    );
}

export const DesktopTesting = (props) => {
    const color = global.themes[props.theme];
    const radius = props.radius;

    const [width, setWidth] = useState(320);
    const height = 120;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
        }
    }, [props.width])



    return (
        <div style={{ ...styles.BlockCenter, marginLeft: props.offset, marginTop: props.offset, width: width, height: height, borderRadius: radius, borderRadius: 20 }} >
            <div style={{ ...styles.Block, width: width, height: height }}>
                <FontAwesomeIcon style={{ height: 30, color: color.dark_red }} icon={faTriangleExclamation} />
                <p style={{ ...styles.TextXLarge, color: color.dark_red, marginTop: 10 }}><b>Testovacia verzia</b></p>
                <p style={{ ...styles.TextXSmall, color: color.dark_red, marginTop: 0 }}>Verzia na testovanie vývojárom aplikácie</p>
            </div>
        </div>
    );
}