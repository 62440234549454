/*
*
* =================================================================================================================
* GALERIA - obklady
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Avatar, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Line, Loading, SelectInput, ShowError, ShowOK, FormLabel, ContactCompany, DialogTabs, TextLine, ButtonNew, TextInput, DialogSMS, DialogSMSBatch } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faBookOpen, faDollar, faImages, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faPrint, faQuestion, faTable, faTableCells, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, GetLeftPosition, GetPages, GetPrice, IsEmpty } from './functions';
import { Photos } from './photos';
import { compareDates, GetDatum2, getDaysBetweenDates } from './functions_date';
import { Customer, CustomerPayment } from './customers';


export const Payments = (props) => {

    var category_id = props.id;

    var lang = props.lang;
    let color = global.themes[props.theme];

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
        paid: false
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // LISTING - GRID / LINES
    const [listingTyp, setListingTyp] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [itemsMax, setItemsMax] = useState(global.items_max);

    // FILTROVANIE
    const [search, setSearch] = useState('');
    const [paid, setPaid] = useState(1);

    // DIALOGS
    const [showPayments, setShowPayments] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [showSMS, setShowSMS] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const boxSize = 280;
    const rowSize = 100;
    const paginationHeight = 50;
    const rowHeight = 100;

    let paid_menu = [
        { id: 1, name: lang.payment_not_paid },
        { id: 2, name: lang.payment_paid },
    ]

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, itemsMax, true);

            running = true;
        }

    }, []);

    const db_get = async (page, filter, items_max, scroll) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'payments', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, items_max));
                setFilter(filter);

                if (scroll == true) {
                    GoToStart();
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeItemsMax = (value) => {
        setItemsMax(value);
        db_get(0, false, value);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, itemsMax, true);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_payments_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectCustomer = (item) => {
        setCustomer(item.customer);
        if (item.customer != false) {
            setShowCustomer(true);
        }
    }

    const CustomerResult = (redraw_) => {
        setShowCustomer(false);

        if (redraw_ == true) {
            db_get(page, filter, itemsMax, false);
        }
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowPayments(true);
    }

    const PaymentResult = (redraw_) => {
        setShowPayments(false);

        if (redraw_ == true) {
            db_get(page, filter, itemsMax, false);
        }

    }

    const ChangePaid = (id) => {
        setPaid(id);

        var filter_ = filter;

        if (id == 0) {
            delete (filter_.paid);
        } else {
            filter_.paid = id == 1 ? false : true;
        }

        db_get(0, filter_, itemsMax, true);
    }


    const Search = (txt) => {
        setSearch(txt);

        var filter_ = filter;

        if (txt.trim() == '') {
            delete (filter_.search);
        } else {
            filter_.search = txt;
        }

        db_get(0, filter_, itemsMax, true);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faDollar} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.Block, borderBottom: '1px solid ' + color.light_gray, height: 90 }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '40%', height: menuHeight }}>
                                        <TextInput search redraw theme={props.theme} width={'100%'} enabled={true} lang={lang} value={search} label={''} placeholder={lang.payment_search_code} func={(txt) => Search(txt)} />
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                        {paid == 1 &&
                                            <Button onClick={() => setShowSMS(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_action, color: color.black }}>{lang.payment_sms}</Button>}
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%', height: menuHeight }}>
                                        <SelectInput all={lang.all_records} theme={props.theme} enabled={true} lang={lang} value={paid} items={paid_menu} field={'name'} label={lang.mobil} radius={global.radius} width={220} func={(item) => ChangePaid(item)} />
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextXTiny, marginTop: 2, color: color.dark_gray }}>{lang.payment_generated}</p>
                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - 94, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div id={'id_payments_start'} style={{ ...styles.Block }}></div>
                                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap', marginTop: 20 }}>
                                    {items != false ? items.map((item, index) => (
                                        <Paper
                                            key={item.id}
                                            elevation={item.id == overID ? 4 : 1}
                                            onClick={() => SelectItem(item)}
                                            style={{
                                                ...styles.Block,
                                                borderRadius: global.items_radius,
                                                backgroundColor: item.paid == false ? color.lighteen_red : color.white,
                                                cursor: 'pointer',
                                                margin: 5,
                                                width: '99%',
                                                height: rowHeight,
                                                borderLeft: item.enabled == false ? color.disabled_border : '',
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny }}>{GetDatum2(item.created)}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '45%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div onClick={(e) => { e.stopPropagation(); SelectCustomer(item) }} style={{ ...styles.BlockLeft, width: 26 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: color.medium_gray }} icon={faUser} />
                                                            </div>
                                                            <TextLine text={item.company != '' ? item.company + ', ' + item.name + ' ' + item.surname : item.name + ' ' + item.surname} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <TextLine text={item.magazine} fontSize={global.font_tiny} color={color.black} />
                                                        <TextLine text={item.subscription} fontSize={global.font_tiny} color={color.black} />
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.payment_code}: {item.code}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 100, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXSmall }}>{GetPrice(item.price)} {lang.money}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 150, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.paid == false ? item.email_sended == 1 ?
                                                        <Chip size='small' label={lang.payment_email_sended_} style={{ backgroundColor: color.white, color: color.dark_red }} />
                                                        :
                                                        item.email_sended == 2 ?
                                                            <Chip size='small' label={lang.payment_email_waiting} style={{ backgroundColor: color.lighteen_red, color: color.light_red }} />
                                                            :
                                                            <Chip size='small' label={lang.payment_email_not_sended} style={{ backgroundColor: color.white, color: color.dark_gray }} />
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 120, marginLeft: 20, minHeight: rowHeight, justifyContent: 'center' }}>
                                                    {item.paid == true ?
                                                        <>
                                                            <Chip size='small' label={lang.payment_paid} style={{ backgroundColor: color.dark_green, color: color.white }} />
                                                            <p style={{ ...styles.TextXTiny, marginTop: 8 }}>{lang.payment_date}</p>
                                                            <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{GetDatum2(item.date)}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <Chip size='small' label={lang.payment_not_paid} style={{ backgroundColor: color.dark_red, color: color.white }} />
                                                            {compareDates(item.created, item.date_end) == 1 ?
                                                                <>
                                                                    <p style={{ ...styles.TextXTiny, marginTop: 8 }}>{lang.payment_expired_}</p>
                                                                    <p style={{ ...styles.TextXTiny, marginTop: 0 }}>dní: {getDaysBetweenDates(item.created, item.date_end)}</p>
                                                                </>
                                                                : null}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                        <EmptyList row small lang={lang} />
                                    }
                                </div>

                                {/* PAGINATION */}
                                <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <div style={{ ...styles.Block, width: 200 }}></div>
                                    <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                        {pages > 1 ?
                                            <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            : null}
                                    </div>
                                    {/*
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={itemsMax} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeItemsMax(item)} />
                                        </div>
                                    </div>
                                </div>
                                */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showSMS == true &&
                <DialogSMSBatch locale={props.locale} system={props.system} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} token={props.token} func={() => setShowSMS(false)} />}

            {showCustomer == true &&
                <Customer item={customer} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={CustomerResult.bind()} />}

            {showPayments == true &&
                <CustomerPayment item={itemSelected} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={PaymentResult.bind()} />}

            {showOK == true &&
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />}

            {showError == true &&
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />}

            {isBusy == true &&
                <Loading offset={props.offset}></Loading>}

        </Paper >
    );
}

