/*
*
* =================================================================================================================
* DÁTUMOVE FUNKCIE
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

/*
*
* =================================================================================================================
* DATUMOVE FUNKCIE
* =================================================================================================================
*
*/

export const GetAge = (str_) => {
    var result = '';

    if (str_ != undefined && str_ != null) {
        var _date = str_.toString().replaceAll('-', '/');

        var date = new Date(_date);
        var diff_ms = Date.now() - date.getTime();
        var age_dt = new Date(diff_ms);
        result = Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    return result;
}

export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetTodayDatum = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = d + '.' + m + '.' + y;

    return today;
}

export const GetTodayYearMonth = () => {
    var today = new Date();

    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m;

    return today;
}

export function AddDays(date_, days) {
    var result = new Date();
    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        result = new Date(date);

        result.setDate(result.getDate() + days);
    }

    return result;
}

export function SubDays(date_, days) {
    var result = new Date();

    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        result = new Date(date);

        result.setDate(result.getDate() - days);
    }

    return result;
}


export const GetDateYear = (date_) => {
    var year = 2023;

    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        var today = new Date(date);
        year = today.getFullYear();
    }

    return year;
}

export const GetDateMonth = (date_) => {
    var month = 1;
    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');

        var today = new Date(date);
        month = today.getMonth() + 1;
    }

    return month;
}

export const GetDateDay = (date_) => {
    var day = 1;

    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        var today = new Date(date);
        day = today.getDate();
    }

    return day;
}

export const GetTodayYear = () => {
    return new Date().getFullYear();
}


export const GetTodayMonth = () => {
    var today = new Date();
    var month = today.getMonth() + 1;
    return month;
}

export const GetHoursFromMinutes = (minutes, lang) => {
    // vrati hodiny z minut
    var hour = parseInt(minutes / 60);
    var minute = minutes - (hour * 60);
    if (minute > 0) {
        if (minutes < 60) {
            hour = minute.toString().padStart(2, '0') + ' ' + lang.minutes;
        } else {
            hour = hour.toString() + ' ' + lang.hour_short + ' ' + minute.toString().padStart(2, '0') + ' ' + lang.minutes;
        }
    } else {
        hour = hour.toString() + ' ' + lang.hour_short;
    }
    return hour;
}

export const GetTodayTime = () => {
    // ------------------------------------
    // RETURN HH:MM:SS
    // ------------------------------------
    var today = new Date();
    var h = String(today.getHours()).padStart(2, '0');
    var m = String(today.getMinutes()).padStart(2, '0');
    var s = String(today.getSeconds()).padStart(2, '0');
    return h + ':' + m + ':' + s;
}


export const GetDatum1 = (months, str_) => {
    // ------------------------------------
    // RETURN DD.MESIAC YYYY / DNES z dátumu
    // ------------------------------------
    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(Date.parse(str));

        var today = Today();

        if (GetDate3(date) != today) {
            var d = String(date.getDate()).padStart(2, '0');
            var m = months[date.getMonth()].toLowerCase();
            var y = String(date.getFullYear());
            result = d + '.' + m + ' ' + y;
        } else {
            result = months[12];
        }
    }

    return result;
}


export const GetDatum2 = (date_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY z dátumu
    // ------------------------------------

    var d = '01';
    var m = '01';
    var y = '2023';

    if (date_ != null && date_ != undefined) {

        var date = date_.toString().replaceAll('-', '/');
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());
    }

    return d + '.' + m + '.' + y;
}


export const GetDatum3 = (months, str_) => {
    // ------------------------------------
    // RETURN DD.MESIAC YYYY HH:MM z dátumu
    // ------------------------------------
    var result = '';
    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);

        var d = String(date.getDate()).padStart(2, '0');
        var m = months[date.getMonth()].toLowerCase();
        var y = String(date.getFullYear());
        result = d + '.' + m + ' ' + y;
    }
    return result;
}

export const GetDatumTime = (months, str_) => {
    // ------------------------------------
    // RETURN DD.MESIAC YYYY HH:MM / DNES z dátumu
    // ------------------------------------

    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);
        var today = Today();

        if (GetDate3(date) != today) {
            var d = String(date.getDate()).padStart(2, '0');
            var m = months[date.getMonth()].toLowerCase();
            var y = String(date.getFullYear());
            var hour = String(date.getHours()).padStart(2, '0');
            var minutes = String(date.getMinutes()).padStart(2, '0');
            result = d + '.' + m + ' ' + y + ', ' + hour + ':' + minutes;
        } else {
            var hour = String(date.getHours()).padStart(2, '0');
            var minutes = String(date.getMinutes()).padStart(2, '0');
            result = months[12] + ', ' + hour + ':' + minutes;
        }
    }
    return result;
}

export const GetDatumTime2 = (str_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY HH:MM
    // ------------------------------------
    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);

        var d = String(date.getDate()).padStart(2, '0');
        var m = String(date.getMonth() + 1).padStart(2, '0');
        var y = String(date.getFullYear());
        var hour = String(date.getHours()).padStart(2, '0');
        var minutes = String(date.getMinutes()).padStart(2, '0');
        result = d + '.' + m + '.' + y + ', ' + hour + ':' + minutes;
    }
    return result;
}

export const GetFromDatumTime = (str_) => {
    // ------------------------------------
    // RETURN HH:MM
    // ------------------------------------
    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);

        var d = String(date.getDate()).padStart(2, '0');
        var m = String(date.getMonth() + 1).padStart(2, '0');
        var y = String(date.getFullYear());
        var hour = String(date.getHours()).padStart(2, '0');
        var minutes = String(date.getMinutes()).padStart(2, '0');
        result = hour + ':' + minutes;
    }
    return result;
}

export const GetTimeHM = (time_) => {
    // ------------------------------------
    // HH:MM
    // ------------------------------------

    var result = '';
    let time = time_.split(':');

    var hour = time[0];
    var minutes = time[1];
    result = hour + ':' + minutes;

    return result;
}


export const GetDateMinusDays = (str_, days) => {
    // ------------------------------------
    // ODPOCITA DEN Z DATUMU A VRATI NAJBLIZSI PRACOVNY DEN
    // ------------------------------------

    var today = new Date();

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        today = new Date(str);

        var d = String(today.getDate() - days).toString().padStart(2, '0');
        var m = String(today.getMonth() + 1).toString().padStart(2, '0');
        var y = String(today.getFullYear());

        today = y + '-' + m + '-' + d;
        var newToday = new Date(today);
        var dow = newToday.getDay();
        if (dow == 0) {
            // nedela
            var d = String(newToday.getDate() - 2).toString().padStart(2, '0');
            var m = String(newToday.getMonth() + 1).toString().padStart(2, '0');
            var y = String(newToday.getFullYear());
        }
        if (dow == 6) {
            // nedela
            var d = String(newToday.getDate() - 1).toString().padStart(2, '0');
            var m = String(newToday.getMonth() + 1).toString().padStart(2, '0');
            var y = String(newToday.getFullYear());
        }

        today = y + '-' + m + '-' + d;
    }

    return today;
}

export const GetDate = (str_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY z dátumu
    // ------------------------------------

    var today = new Date();

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        today = new Date(str);

        var d = String(today.getDate());
        var m = String(today.getMonth() + 1);
        var y = String(today.getFullYear());
        today = d + '.' + m + '.' + y;
    }

    return today;
}


export const GetDate2 = (str) => {
    // ------------------------------------
    // RETURN YYYY-MM-DD / YYYY-MM z dátumu
    // ------------------------------------

    var tmp = str.split('.');
    var date = null;
    if (tmp.length == 3) {
        var d = tmp[0].toString().padStart(2, '0');
        var m = tmp[1].toString().padStart(2, '0');;
        var y = tmp[2];
        date = y + '-' + m + '-' + d;
    }
    if (tmp.length == 2) {
        var m = tmp[0].toString().padStart(2, '0');;
        var y = tmp[1];
        date = y + '-' + m;
    }
    if (tmp.length == 1) {
        var y = tmp[0];
        date = y;
    }

    return date;
}

export const GetDate3 = (str_) => {
    // ------------------------------------
    // RETURN YYYY-MM-DD z dátumu
    // ------------------------------------
    var today = '';
    if (str_ != undefined && str_ != null) {
        var str = str_.toString().replaceAll('-', '/');
        today = new Date(str);

        var d = String(today.getDate()).padStart(2, '0');
        var m = String(today.getMonth() + 1).padStart(2, '0');
        var y = String(today.getFullYear());
        today = y + '-' + m + '-' + d;
    }

    return today;
}

export const GetMiliseconds = (date_) => {
    var result = 0;
    if (date_ != undefined && date_ != null) {
        var str = date_.toString().replaceAll('-', '/');
        var date = new Date(date_);

        result = date.getTime();
    }

    return result;
}


export const CreateDate = (year, month, day) => {
    // ------------------------------------
    // RETURN YYYY-MM-DD z Y,M,D
    // ------------------------------------
    var today = '';

    var d = String(day).padStart(2, '0');
    var m = String(month).padStart(2, '0');
    var y = String(year);

    today = y + '-' + m + '-' + d;

    return today;
}

export const GetHours = (minutes) => {
    // vrati hodiny z minut
    var hour = parseInt(minutes / 60);
    var minute = minutes - (hour * 60);
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

export const GetMinutesFromHours = (time_) => {
    // vrati pocet minut z hodin
    var time = time_.split(':');
    return (parseInt(time[0]) * 60) + parseInt(time[1]);
}

export function getDaysBetweenDates(date1, date2) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    const timeDifference = Math.abs(endDate - startDate);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
}

export function compareDates(date1, date2) {
    var date_1 = GetMiliseconds(date1);
    var date_2 = GetMiliseconds(date2);

    return date_1 < date_2 ? -1 : date_1 == date_2 ? 0 : 1;
}


export function addMonthsToDate(date_, months) {
    var date = new Date(date_.toString().replaceAll('-', '/'));

    let resultDate = new Date(date);

    // Add the specified number of months
    resultDate.setMonth(resultDate.getMonth() + months);

    // Handle cases where adding months might create an invalid date
    // e.g., adding 1 month to January 31st should result in the last day of February
    if (resultDate.getDate() !== date.getDate()) {
        resultDate.setDate(0);
    }

    return GetDate3(resultDate);
}