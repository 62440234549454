/*
*
* =================================================================================================================
* GALERIA - obklady
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Avatar, EmptyList, FormError, FormSpace, FormText, FormYesNo, Icon, Line, Loading, SelectInput, ShowError, ShowOK, FormLabel, ContactCompany, DialogTabs, TextLine, ButtonNew, FormSelect, FormNote } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, IconButton, Pagination, Paper, Skeleton, Stack, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faBookOpen, faImages, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faPrint, faQuestion, faTable, faTableCells, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, GetLeftPosition, GetPages, GetPrice, IsEmpty } from './functions';
import { Photos } from './photos';


export const Magazines = (props) => {

    var category_id = props.id;

    var lang = props.lang;
    let color = global.themes[props.theme];

    let defaultFilter = {
        ordering: global.order.down,
        order_column: 'name',
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // LISTING - GRID / LINES
    const [listingTyp, setListingTyp] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [itemsMax, setItemsMax] = useState(global.items_max);

    // DIALOGS
    const [showMagazine, setShowMagazine] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const menuHeight = 50;
    const boxSize = 280;
    const rowSize = 100;
    const paginationHeight = 50;
    const rowHeight = 94;


    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter, itemsMax);

            running = true;
        }

    }, []);

    const db_get = async (page, filter, items_max) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'magazines', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    start: page * items_max,
                    length: items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setPage(page);
                setPages(GetPages(json.count, items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'magazine_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                db_get(0, filter, itemsMax);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeItemsMax = (value) => {
        setItemsMax(value);
        db_get(0, false, value);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter, itemsMax);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_magazines_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowMagazine(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowMagazine(true);
    }

    const MagazineResult = (redraw_) => {
        setShowMagazine(false);

        if (redraw_ == true) {
            db_get(0, filter, itemsMax);
        }

    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faImages} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                    <div id={'id_magazines_start'} style={{ ...styles.Block }}></div>

                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.magazines_list}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                        {lang.magazine_new}
                                    </Button>
                                </div>
                            </div>

                            <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap', marginTop: 20 }}>
                                {items != false ? items.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => SelectItem(item)}
                                        style={{
                                            ...styles.Block,
                                            borderRadius: global.items_radius,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            cursor: 'pointer',
                                            margin: 5,
                                            width: '99%',
                                            height: rowHeight,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: '90%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} marginLeft={5} color={color.black} />
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }
                            </div>

                            {/* PAGINATION */}
                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: global.empty_padding, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <div style={{ ...styles.Block, width: 200 }}></div>
                                <div style={{ ...styles.Block, width: '90%', height: paginationHeight }}>
                                    {pages > 1 ?
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockRight, width: 100, height: 36, height: paginationHeight }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'right', lineHeight: 1 }}>{lang.items_count}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 90, marginLeft: 10, height: paginationHeight }}>
                                            <SelectInput theme={props.theme} enabled={true} lang={lang} value={itemsMax} items={global.max_items_on_page} field={'label'} label={lang.mobil} radius={global.radius} func={(item) => ChangeItemsMax(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showMagazine == true ?
                <Magazine item={itemSelected} user={props.user} languages={props.languages} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={MagazineResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Magazine = (props) => {


    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [overID, setOverID] = useState(-1);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState('');
    const [note, setNote] = useState('');

    // PREDPLATNE
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    // CONSTANTS
    const tabHeight = itemID == 0 ? 0 : 40;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 150;
    const column2 = 400;
    const rowHeight = 80;

    const tabs = [
        { id: 0, enabled: true, label: lang.setting },
        { id: 1, enabled: true, label: lang.subscriptions },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (itemID > 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'magazine', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_subscriptions = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'subscriptions', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    magazine_id: itemID,
                    filter: false,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'magazine_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);

                if (data.id == 0) {
                    setItemID(json.ok);
                }
                setRedrawRequired(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setName(item.name);
        setNote(item.note);
        setAvatar(item.avatar);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.name = name;
        storeData.note = note;
        storeData.avatar = avatar;
    }

    const Save = () => {
        setError('');
        var error = false;

        error += IsEmpty(name) == true ? 1 : 0;

        if (error == 0) {

            let data = {
                id: itemID,
                enabled: enabled,
                name: name.trim(),
                note: note,
                avatar: avatar,
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const AvatarPress = (typ) => {
        if (typ == true) {
            setSelectPhoto(true);
        } else {
            setAvatar('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const ChangeTab = (id) => {
        setTabIndex(id);

        if (id == 1) {
            db_subscriptions();
        }
    }

    const CheckChanges = () => {
        var changes = 0;

        changes += avatar != storeData.avatar ? 1 : 0;
        changes += enabled != storeData.enabled ? 1 : 0;
        changes += name != storeData.name ? 1 : 0;
        changes += note != storeData.note ? 1 : 0;

        return changes == 0 ? false : true;
    }

    const ButtonNewResult = () => {
        setItemSelected(false);
        setShowSubscription(true);
    }

    const SelectItem = (item) => {
        setItemSelected(item);
        setShowSubscription(true);
    }

    const SubscriptionResult = (redraw_) => {
        setShowSubscription(false);

        if (redraw_ == true) {
            db_subscriptions();
        }
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.magazine_new : lang.magazine}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    {itemID != 0 ?
                        <div style={{ ...styles.Block, height: tabHeight }}>
                            <DialogTabs value={tabIndex} items={tabs} width={dialogWidth / 2} theme={props.theme} func={(id) => ChangeTab(id)} />
                        </div>
                        : null}

                    <div style={{ ...styles.BlockCenter, height: tabIndex == 0 ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight : dialogHeight - global.dialog_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {tabIndex == 0 ?
                            <>
                                {storeData != false ?
                                    <div>
                                        <FormSpace />
                                        <div style={{ ...styles.Block }}>
                                            <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                            <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                        </div>
                                        <FormSpace />
                                        <FormLabel editing={editing} editButton={true} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                        <FormYesNo value={enabled} editing={editing} title={lang.customer_active} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                        <FormText require redraw value={name} editing={editing} title={lang.magazine_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                        <FormText redraw value={note} editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                        <FormSpace height={global.empty_padding} />

                                    </div>
                                    :
                                    <Stack style={{ ...styles.Block }} spacing={1}>
                                        <FormSpace />
                                        <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                        <FormSpace />
                                        <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    </Stack>}
                            </>
                            : null}

                        {tabIndex == 1 ?
                            <div style={{ ...styles.BlockCenter, width: '94%' }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%', height: 60 }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.subscription_list}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%', height: 60 }}>
                                        <ButtonNew icon={faPlusCircle} menuItems={false} label={lang.subscription_new} theme={props.theme} func={ButtonNewResult.bind()} />
                                    </div>
                                </div>

                                {items != false ? items.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => SelectItem(item)}
                                        style={{
                                            ...styles.Block,
                                            borderRadius: global.items_radius,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            cursor: 'pointer',
                                            marginTop: 5,
                                            width: '98%',
                                            height: rowHeight,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '94%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.avatar} size={rowHeight - 20} theme={props.theme} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: '70%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                <TextLine text={item.name} fontSize={global.font_xsmall} fontWeight={'600'} marginLeft={5} color={color.black} />
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 120, minHeight: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextXSmall }}>{GetPrice(item.price)} {lang.money}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <div style={{ ...styles.Block, paddingTop: 50 }}>
                                        <EmptyList row small lang={lang} />
                                    </div>
                                }
                                <FormSpace height={global.empty_padding} />

                            </div>
                            : null}

                    </div>
                    {tabIndex == 0 ?
                        <>
                            <div style={{ ...styles.Block, height: errorHeight }}>
                                <FormError small margin={0} error={error} theme={props.theme} />
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {tabIndex == 0 && editing == true && CheckChanges() == true ?
                                        <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                        : null}
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                                </div>
                            </div>
                        </>
                        : null}
                </div >

                {showSubscription == true ?
                    <Subscription magazineID={itemID} item={itemSelected} user={props.user} languages={props.languages} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={SubscriptionResult.bind()} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.avatars} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


/*
*
* =================================================================================================================
* PREDPLATNE -> UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Subscription = (props) => {


    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [redrawRequired, setRedrawRequired] = useState(false);
    const [magazineID, setMagazineID] = useState(props.magazineID);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA
    const [storeData, setStoreData] = useState({});

    const [enabled, setEnabled] = useState(true);
    const [languageID, setLanguageID] = useState(1);
    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState('');
    const [price, setPrice] = useState('0');
    const [months, setMonths] = useState('12');
    const [note, setNote] = useState('');

    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 580 ? 580 : width;
    const dialogHeight = height >= 720 ? 720 : height;

    // CONSTANTS
    const tabHeight = 0;
    const buttonHeight = 70;
    const errorHeight = 40;
    const column1 = 240;
    const column2 = dialogWidth - 40 - column1;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            if (itemID > 0) {
                // EDITACIA POLOZKY -> UDAJE Z DB
                db_get();
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'subscription', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setStoreData(item);

                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'subscription_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);

                if (data.id == 0) {
                    setItemID(json.ok);
                }
                setRedrawRequired(true);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setEnabled(item.enabled == false ? false : true);
        setLanguageID(item.language_id);
        setName(item.name);
        setPrice(item.price);
        setMonths(item.months);
        setNote(item.note);
        setAvatar(item.avatar);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.enabled = enabled;
        storeData.language_id = languageID;
        storeData.avatar = avatar;
        storeData.name = name;
        storeData.price = price;
        storeData.months = months;
        storeData.note = note;

    }

    const Save = () => {
        setError('');
        var error = false;

        error += IsEmpty(name) == true ? 1 : 0;
        error += IsEmpty(price) == true ? 1 : 0;
        error += IsEmpty(months) == true ? 1 : 0;

        if (error == 0) {

            let data = {
                id: itemID,
                enabled: enabled,
                magazine_id: magazineID,
                language_id: languageID,
                avatar: avatar,
                name: name.trim(),
                price: price,
                months: months,
                note: note,
            };

            StoreEditing();

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const AvatarPress = (typ) => {
        if (typ == true) {
            setSelectPhoto(true);
        } else {
            setAvatar('');
        }
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const CheckChanges = () => {
        var changes = 0;

        changes += enabled != storeData.enabled ? 1 : 0;
        changes += languageID != storeData.language_id ? 1 : 0;
        changes += avatar != storeData.avatar ? 1 : 0;
        changes += name != storeData.name ? 1 : 0;
        changes += note != storeData.note ? 1 : 0;
        changes += price != storeData.price ? 1 : 0;
        changes += months != storeData.months ? 1 : 0;

        return changes == 0 ? false : true;
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : GetLeftPosition(width), backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 2000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faUser} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.subscription_new : lang.subscriptions}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: tabIndex == 0 ? dialogHeight - global.dialog_bar_height - tabHeight - buttonHeight - errorHeight : dialogHeight - global.dialog_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {storeData != false ?
                            <div>
                                <FormSpace />
                                <div style={{ ...styles.Block }}>
                                    <Avatar editable={editing} picture image={avatar} size={75} theme={props.theme} func={AvatarPress.bind()} />
                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.default_image}</p>
                                </div>
                                <FormSpace />
                                <FormLabel editing={editing} editButton={true} icon={faInfoCircle} title={lang.basic_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={() => setEditing(true)} />
                                <FormYesNo value={enabled} editing={editing} title={lang.customer_active} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(value) => setEnabled(value)} />
                                <FormSelect require value={languageID} items={props.languages} field={'country'} editing={editing} title={lang.subscription_language} width={column2} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setLanguageID(id)} />
                                <FormNote title={lang.subscription_language_note} marginBottom={0} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText require redraw value={name} editing={editing} title={lang.magazine_name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                                <FormText redraw value={price} numeric editing={editing} title={lang.subscription_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPrice(txt)} />
                                <FormText redraw value={months} numeric editing={editing} title={lang.subscription_months} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMonths(txt)} />
                                <FormText redraw value={note} numeric editing={editing} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                <FormSpace height={global.empty_padding} />

                            </div>
                            :
                            <Stack style={{ ...styles.Block }} spacing={1}>
                                <FormSpace />
                                <Skeleton variant="circular" animation="wave" width={column1 + column2} style={{ width: 75, height: 75 }} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                            </Stack>
                        }
                    </div>

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {tabIndex == 0 && editing == true && CheckChanges() == true ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 20 }}>{lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.avatars} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => func(true)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}

